import Vue from "vue";

export default {

    recuperarCartaoConfirmacao(processoOid) {
        return Vue.axios.get("cartaoConfirmacao/" + processoOid)
    },

    recuperarCartaoConfirmacaoAdministrador(inscricaoOid) {
        return Vue.axios.get("cartaoConfirmacao/administrador", {
            params: {
                inscricaoOid: inscricaoOid
            }
        });
    },

    recuperarCartaoConfirmacaoPdf(processoOid){
        return Vue.axios.get("cartaoConfirmacao/pdf", { params: { processoOid },
            responseType: "arraybuffer",
                headers: {
                'Accept': "application/pdf"
            }
        });
    },

    recuperarCartaoConfirmacaoPdfAdministrador(inscricaoOid){
        return Vue.axios.get("cartaoConfirmacao/administrador/pdf", { params: { inscricaoOid },
            responseType: "arraybuffer",
            headers: {
                'Accept': "application/pdf"
            }
        });
    }
};