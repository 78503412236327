import Vue from "vue";

export default {
    recuperarNecessidadesEspeciaisPorInscricao(inscricaoOid) {
        return Vue.axios.get("necessidadeEspecial/inscricao/" + inscricaoOid)
    },

    deletarNecessidadeEspecialPorInscricao(necessidadeEspecialOid) {
        return Vue.axios.delete("necessidadeEspecial/inscricao/" + necessidadeEspecialOid)
    }
};