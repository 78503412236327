import Vue from "vue";

export default {
  enviarReciboEmail(inscricao) {
    return Vue.axios.post("recibo/enviarReciboEmail/" + inscricao.oid);
  },

  recuperarRecibo(processoOid) {
    return Vue.axios.get("recibo", { params: { processoOid: processoOid } });
  },

  recuperarReciboPdf(processoOid) {
    return Vue.axios.get("recibo/pdf", { params: { processoOid },
      responseType: "arraybuffer",
      headers: {
        'Accept': "application/pdf"
      }
    });
  },

  recuperarReciboPdfAdministrador(inscricao) {
    return Vue.axios.get("recibo/pdf/candidato/" + inscricao.oid, {
      responseType: "arraybuffer",
      headers: {
        'Accept': "application/pdf"
      }
    });
  }
};
